import React from "react"
import styled from "styled-components"
//import Img from "gatsby-image"
import { Link } from "gatsby"
import LogoSvg from '../../data/SVG/striped-logo.svg'

const LogoWrap = styled.div`
  margin: auto 0;
  flex: 0 1 110px;

  @media (max-height: 600px) {
    flex: 0 1 65px;
  }

  @media (max-width: 768px) and (orientation: landscape) {
    flex: 0 1 95px;
  }
  @media (min-height: 1000px) and (orientation: landscape) {
    flex: 0 1 190px;
  }
`

const LogoImage = styled.img`
  height: auto;
  max-width: 100%;
`

const Logo = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     file(name: { eq: "logo-notext-new" }, extension: { eq: "png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 50, pngQuality: 80) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)
  // console.log(data)
  return (
    <LogoWrap as={Link} to="/">
      {/* <Img fluid={data.file.childImageSharp.fluid} alt="logo" /> */}
      <LogoImage src={LogoSvg} alt='logo' />
    </LogoWrap>
  )
}

export default Logo