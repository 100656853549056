import React from "react"
//import Navi from "./Navbars/NavbarT"
import Navbar from "./Navbar/Navbar"
import Footer from "./Footer"
import { Helmet } from "react-helmet"


export default function Layout({ children }) {
  return (
    <div>

      <Helmet>
        <html lang='en'/>
        <meta charSet="utf-8" />
        <title>Dr. Earl Winer Optometry</title>
        <link rel="canonical" href="drwiner.ca" />
        <meta name="description" content="Dr. Winer Markham Optometry" />
      </Helmet>

      <Navbar />
      
      <div className='layout-style'>
        {children}
        <Footer />
      </div>

    </div>
  )
}
