import React from "react"
import { Link } from "gatsby"
import mailSVG from "../data/SVG/white-mail.svg"
import phoneSVG from "../data/SVG/white-phone.svg"

export default function Footer() {
  return (
    <div>
      <footer className="bg-near-black pv4 ph0 ph5-m ph6-l near-white">
        {/* <h3 className="tc pa3">Dr. Winer Optometry</h3> */}
        <div className="flex flex-column flex-row-l justify-center ">
          <div className="flex flex-column w-100 w-30-l items-center mb4">
            <p className="b pb3 underline">Useful Links</p>
            <Link to="/contact" title="contact" className="f4 link dim near-white">
              Contact
            </Link>
            <Link to="/" title="home" className="f4 link dim near-white">
              Home
            </Link>
            <Link to="/blog" title="blog" className="f4 link dim near-white">
              Blog
            </Link>
          </div>
          <div className="flex flex-column w-100 w-30-l items-center mb4">
            <p className="b pb3 underline">Contact Us</p>
            <a className="white dim no-underline tc" href="mailto:office@drwiner.ca" target="__blank">
              <b>Email:</b> office@drwiner.ca
            </a>
            <a className="white dim no-underline tc" href="tel:9059409988">
              <b>Tel:</b> (905)940 9988
            </a>
            <a className="no-underline white dim tc" href="https://goo.gl/maps/gLUJtfHbwCX49Yas9" target="__blank">
              8601 Warden Ave, Markham
            </a>
          </div>
          <div className="flex flex-column w-100 w-30-l items-center mb4">
            <p className="b pb3 underline">Have a question?</p>
            <a className="dim white no-underline" href="tel:9059409988">
              <img width="20" height="auto" src={phoneSVG} alt="phone" /> Give us a call.
            </a>
            <a className="dim dib white no-underline" href="mailto:office@drwiner.ca" target="__blank">
              <img width="20" height="auto" src={mailSVG} alt="mail" /> Shoot us an email.
            </a>
          </div>
        </div>
        <div className="f6 db tc mt3 flex flex-column items-center">
          <div className=" bg-blue pv3 ph4 mb4 br3">
            <a
              className="dim f3 white no-underline "
              href="https://g.page/r/CQGRYkQOFx8GEBI/review"
              target="__blank"
            >
              Had a good experience? Leave us a review here.
            </a>
          </div>
          <div>
            © 2021 <b className="ttu">Dr. Earl Winer Optometry</b>. All Rights Reserved.
          </div>
        </div>
      </footer>
    </div>
  )
}
